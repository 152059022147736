import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { Routes, Route } from 'react-router-dom';

import { theme, mediaQueries } from 'common/theme';
import { formatISODateWithoutYear } from 'common/utils';
import { Row } from 'components/BasicComponents';
import { Icons } from 'common/Icons';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';

const Maintenance = ({
    data,
    edit,
    isEditable,
    blueButton,
    redButton,
    isLabel,
    isPrinting,
    canWrite,
    canDelete,
    canSeeAuthors,
}) => {
    const { t } = useTranslation();
    const { date, name, task, endDate, finished, createdBy } = data || {};

    if (isLabel) {
        return (
            <ReservationStyled isLabel>
                <WrapperStyled>
                    <ColumnStyled className="date">{t('Datum')}</ColumnStyled>
                    <ColumnStyled className="task">{t('Závada')}</ColumnStyled>
                    <ColumnStyled className="name">
                        {t('Kdo provede')}
                    </ColumnStyled>
                    <ColumnStyled className="date">{t('Do kdy')}</ColumnStyled>
                    <ColumnStyled className="finished">
                        {t('Splněno')}
                    </ColumnStyled>
                    {canSeeAuthors && (
                        <ColumnStyled className="finished">
                            {t('Zapsal')}
                        </ColumnStyled>
                    )}
                    <ColumnStyled className="buttons"></ColumnStyled>
                </WrapperStyled>
            </ReservationStyled>
        );
    }

    const reservation = (showButtons) => {
        return (
            <ReservationStyled isEditable={isEditable}>
                <WrapperStyled>
                    <ColumnStyled className="date">
                        {date && !isEditable && (
                            <>
                                <i className={Icons.calendar} />
                                {formatISODateWithoutYear(date)}
                            </>
                        )}
                    </ColumnStyled>

                    <ColumnStyled className="task">
                        {isEditable ? (
                            <Input
                                type="text"
                                value={task}
                                name="task"
                                onChange={(e) => edit(e)}
                                placeholder="Závada"
                            />
                        ) : (
                            task
                        )}
                    </ColumnStyled>

                    <ColumnStyled className="name">
                        {isEditable ? (
                            <Input
                                type="text"
                                value={name}
                                name="name"
                                onChange={(e) => edit(e)}
                                width="100px"
                                placeholder="Kdo provede"
                            />
                        ) : (
                            name
                        )}
                    </ColumnStyled>

                    <ColumnStyled className="date">
                        <i className={Icons.calendar} />
                        {isEditable ? (
                            <Input
                                type="date"
                                value={endDate}
                                name="endDate"
                                onChange={(e) => edit(e)}
                            />
                        ) : (
                            formatISODateWithoutYear(endDate)
                        )}
                    </ColumnStyled>

                    <ColumnStyled className="finished">
                        {isEditable ? (
                            <>
                                <Input
                                    type="checkbox"
                                    checked={finished}
                                    name="finished"
                                    onChange={(e) => edit(e)}
                                />
                                {t('Splněno')}
                            </>
                        ) : finished ? (
                            <>
                                <FinishedStyled />
                                {t('Splněno')}
                            </>
                        ) : (
                            <>
                                <NotFinishedStyled />
                                {t('Nesplněno')}
                            </>
                        )}
                    </ColumnStyled>

                    {canSeeAuthors && (
                        <ColumnStyled className="created">
                            {createdBy}
                        </ColumnStyled>
                    )}

                    {showButtons && (
                        <Routes>
                            <Route
                                path="edit"
                                element={
                                    canWrite || canDelete ? (
                                        <ColumnStyled className="buttons">
                                            {canWrite && (
                                                <Button
                                                    type={
                                                        isEditable
                                                            ? 'greenFull'
                                                            : 'blueFull'
                                                    }
                                                    onClick={blueButton}
                                                    icon={
                                                        isEditable
                                                            ? 'save'
                                                            : 'edit'
                                                    }
                                                    mx="5px"
                                                    my="5px"
                                                />
                                            )}

                                            {!isEditable &&
                                            !canDelete ? null : (
                                                <Button
                                                    type="redFull"
                                                    onClick={redButton}
                                                    icon={
                                                        isEditable
                                                            ? 'cancel'
                                                            : 'delete'
                                                    }
                                                    mx="5px"
                                                    my="5px"
                                                />
                                            )}
                                        </ColumnStyled>
                                    ) : null
                                }
                            />
                        </Routes>
                    )}
                </WrapperStyled>
            </ReservationStyled>
        );
    };

    return reservation(!isPrinting);
};

export default Maintenance;

const highlight = keyframes`
  0% {
    background: ${theme.colors.active.secondary};
  }
  50% {
    background: ${theme.colors.active.primary};
  }
  100% {
    background: ${theme.colors.active.secondary};
  }
`;

const ReservationStyled = styled(Row)`
    background: ${(props) =>
        props.isLabel
            ? `${props.theme.colors.label}22`
            : props.isEditable
            ? props.theme.colors.editable.background
            : props.bg};
    color: ${(props) => (props.isLabel ? props.theme.colors.label : '')};
    font-weight: ${(props) => (props.isLabel ? '800' : '')};
    width: 100%;
    max-width: 90rem;
    min-height: 2.5rem;
    line-height: 2.5rem;
    border-bottom: ${(props) =>
        props.isLabel ? 0 : `1px solid rgba(0, 0, 0, 0.1)`};
    padding: 0.5rem;
    animation-name: ${(props) => (props.isActive ? highlight : '')};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    @media ${mediaQueries.s} {
        display: ${(props) => (props.isLabel ? 'none' : '')};
    }
`;

const WrapperStyled = styled(Row)`
    width: 100%;
    flex-wrap: wrap;
    @media ${mediaQueries.s} {
        flex-direction: column;
        align-items: center;
        padding: 0.5rem;
    }
`;

const ColumnStyled = styled(Row)`
    text-align: center;
    padding: 0 0.5rem;
    align-items: center;
    opacity: ${(props) => (props.isHidden ? '0.5' : '')};
    text-decoration: ${(props) => (props.isHidden ? 'line-through' : '')};
    & i {
        margin-right: 0.5rem;
    }
    &.date {
        min-width: 9rem;
        @media ${mediaQueries.s} {
            justify-content: flex-start;
        }
    }
    &.task {
        min-width: 25rem;
        max-width: 25rem;
        font-weight: 800;
        text-align: left;
        @media ${mediaQueries.s} {
            justify-content: center;
            width: 100%;
        }
    }
    &.name {
        min-width: 12rem;
    }
    &.finished {
        min-width: 7rem;
        justify-content: center;
    }
    &.created {
        font-size: 0.8rem;
    }
    &.buttons {
        min-width: 11rem;
        @media ${mediaQueries.s} {
            width: 100%;
        }
    }
`;

const FinishedStyled = styled(Row)`
    width: 15px;
    min-width: 15px;
    height: 15px;
    border-radius: 15px;
    background: #00c300;
    margin-right: 5px;
`;
const NotFinishedStyled = styled(Row)`
    width: 15px;
    min-width: 15px;
    height: 15px;
    border-radius: 15px;
    background: red;
    margin-right: 5px;
`;
